<template>
  <div class="BlogDetail container">
    <div class="row" itemscope itemtype="https://schema.org/BlogPosting">
      <div class="col-12">
        <h1><span class="font-family-krona font-size-200" itemprop="headline">{{blog.content.Title}}</span></h1>
        <meta itemprop="image" :content="blog.content.Thumbnail" />
        <small class="text-gray font-size-085">Published at <span class="text-primary" itemprop="datePublished" :content="blog.first_published_at">{{$formatDateWithTime(blog.first_published_at)}}</span></small>
        <span class="sr-only" itemprop="author">Dxboard</span>
        <div class="content mt-3" itemprop="articleBody">
          <template v-for="(section,index) in blog.content.Content.content" v-bind:key="index">
            <!-- PARAGRAPH -->
            <template v-if="section.type=='paragraph'">
              <Paragraph :section="section"></Paragraph>
            </template>
            <!-- HEADINGS -->
            <template v-else-if="section.type=='heading'">
              <Heading :section="section"></Heading>
            </template>
            <!-- LIST -->
            <template v-else-if="section.type=='bullet_list'">
              <BulletsList :section="section"></BulletsList>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

import Paragraph from './templates/Paragraph.vue'
import Heading from './templates/Heading.vue'
import BulletsList from './templates/BulletsList.vue'

export default {
  name: 'BlogDetail',
  data() {
    return {
      slug: this.$route.params.slug,
      blog : ''
    };
  },
  created() {
    axios.get(`https://api.storyblok.com/v2/cdn/stories/blog/${this.slug}?token=39VzSHOswQcS3rnQER7nrQtt`)
      .then(async (response)=> {
        this.blog = response.data.story;
        this.$emit('widgetLoaded');
      });
    
  },
  components: {
    Paragraph,
    Heading,
    BulletsList
  },
  methods: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

</style>
